import siteMixin from "@/mixins/siteMixin";
import userMixin from "@/mixins/userMixin";
export default {
    data: () => ({
        user: null,
        site: null,
        namespace: null,
        headers: {},
        controller: null,
    }),
    methods: {
        prepareForFetch(namespace) {
            this.user = this.getCurrentUser();
            if (!this.user || !this.user.auth) {
                return;
            }
            if (this.controller) {
                this.controller.abort();
            }
            this.controller = new AbortController();
            this.namespace = namespace || "/twd-dashboard/v1";
            this.site = this.getSite();

            if (process.env.NODE_ENV == "production") {
                if (this.site && this.site.url) {
                    this.site.url = this.site.url.replace("http://", "https://");
                }
            }

            this.headers = { Authorization: "Basic " + this.user.auth };
        },
        async dashboardInfo(from, to, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.site.url + "/wp-json" + this.namespace + "/dashboard", {
                headers: this.headers,
                params: { from: from, to: to },
            });
        },
        async getPosts(page = 1, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.site.url + "/wp-json/wp/v2/posts?_embed=1&context=edit", {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                params: { page: page },
            });
        },
        async getPost(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.site.url + `/wp-json/wp/v2/posts/${id}?_embed=1&context=edit`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async getNews(page = 1, perPage = 10, order = "desc", orderBy = "date", querySearch = "", filterDate = null, namespace) {
            // get post_type post and tw_alerts
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            let params = {
                page: page,
                per_page: perPage,
                order: order, // 'asc'
                orderby: orderBy,
                status: "publish, future, draft, private",
                search: querySearch,
            };
            if (filterDate) {
                console.log(this.moment(filterDate).startOf("month").format("Y-MM-DDTHH:mm:ss"));
                console.log(this.moment(filterDate).endOf("month").format("Y-MM-DDTHH:mm:ss"));
                // params = {...params, ...{after: '2021-12-31T23:00:00'}}
                params = { ...params, ...{ after: this.moment(filterDate).startOf("month").format("Y-MM-DDTHH:mm:ss") } };
                // params = {...params, ...{before: '2022-01-31T22:59:59'}}
                params = { ...params, ...{ before: this.moment(filterDate).endOf("month").format("Y-MM-DDTHH:mm:ss") } };
                // 2019-10-03T13:00:00
            }

            try {
                return await this.axios.get(this.site.url + "/wp-json/wp/v2/site-news", {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                    signal: this.controller.signal,
                    params: params,
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        // Function Parse Page Content
        async parsePageContent(page) {
            return await this.axios.post(process.env.VUE_APP_JUNIPER_API + `page-builder/parse/page`, page, {
                headers: this.headers,
            });
        },
        async savePost(post, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            // var formData = new FormData();
            // formData.append('categories', categories);
            // formData.append('title', title);
            // formData.append('content', content);
            // if (mediaId) {
            // 	formData.append('featured_media', mediaId);
            // }
            // formData.append('status', status);

            // console.log(title)
            // {categories: [4], status: "publish", featured_media: 8538, title: "test iz dash", content}
            //featured_media ->id
            return await this.axios.post(this.site.url + `/wp-json/wp/v2/posts?tw_dashboard_departments=1`, post, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async updatePost(post, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.site.url + `/wp-json/wp/v2/posts/${post.id}`, post, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async deletePost($id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.site.url + "/wp-json/wp/v2/posts/" + $id, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
            });
        },
        async getTicker(categories = [], page = 1, perPage = 10, order = "desc", orderBy = "date", namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            let params = {
                page: page,
                per_page: perPage,
                order: order, // 'asc'
                orderby: orderBy,
                status: "publish",
                categories: categories,
            };

            return await this.axios.get(this.site.url + "/wp-json/wp/v2/posts", {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                params: params,
            });
        },
        async getAlerts(page = 1, perPage = 10, order = "desc", orderBy = "date", querySearch = "", filterDate = null, namespace) {
            // get post_type tw_alerts
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            let params = {
                page: page,
                per_page: perPage,
                order: order, // 'asc'
                orderby: orderBy,
                status: "publish, future, draft",
                search: querySearch,
            };
            if (filterDate) {
                console.log(this.moment(filterDate).startOf("month").format("Y-MM-DDTHH:mm:ss"));
                console.log(this.moment(filterDate).endOf("month").format("Y-MM-DDTHH:mm:ss"));
                // params = {...params, ...{after: '2021-12-31T23:00:00'}}
                params = { ...params, ...{ after: this.moment(filterDate).startOf("month").format("Y-MM-DDTHH:mm:ss") } };
                // params = {...params, ...{before: '2022-01-31T22:59:59'}}
                params = { ...params, ...{ before: this.moment(filterDate).endOf("month").format("Y-MM-DDTHH:mm:ss") } };
                // 2019-10-03T13:00:00
            }

            try {
                return await this.axios.get(this.site.url + `/wp-json/wp/v2/tw_alerts?_embed=1&context=edit&status=publish`, {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                    signal: this.controller.signal,
                    params: params,
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getAlert(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.site.url + `/wp-json/wp/v2/tw_alerts/${id}?_embed=1&context=edit`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async saveAlert(alert, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.site.url + `/wp-json/wp/v2/tw_alerts`, alert, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async updateAlert(alert, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.site.url + `/wp-json/wp/v2/tw_alerts/${alert.id}`, alert, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async deleteAlert($id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.site.url + "/wp-json/wp/v2/tw_alerts/" + $id, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
            });
        },
        /*=============================
		=            PAGES            =
		=============================*/
        async getPages(page = 1, perPage = 10, order = "desc", orderBy = "date", querySearch = "", showTrashed, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            showTrashed;
            try {
                return await this.axios.get(this.site.url + "/wp-json/wp/v2/pages?_embed=1&context=edit", {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                    signal: this.controller.signal,
                    params: {
                        page: page,
                        per_page: perPage,
                        order: order, // 'asc'
                        orderby: orderBy,
                        search: querySearch,
                        status: "any",
                    },
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getPage(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.site.url + `/wp-json/wp/v2/pages/${id}?_embed=1&context=edit`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async savePage(page, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.site.url + `/wp-json/wp/v2/pages`, page, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async savePageFromPageBuilder(page, namespace) {
            console.log("worpdress savePageFromPageBuilder");
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            let id = page.id ?? "";

            return await this.axios.post(this.site.url + `/wp-json/wp/v2/pb-pages/${id}`, page, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async updatePageFromPageBuilder(page, namespace) {
            console.log("worpdress updatePageFromPageBuilder");
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            let id = page.id ?? "";

            return await this.axios.post(this.site.url + `/wp-json/wp/v2/pb-pages/${id}`, page, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async updatePage(page, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.site.url + `/wp-json/wp/v2/pages/${page.id}`, page, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        /*=====  End of PAGES  ======*/
        /*==============================
		=            EVENTS            =
		==============================*/
        async getEvents(page = 1, perPage = 10, order = "desc", orderBy = "date", querySearch = "", startDate, endDate, namespace) {
            // async getEvents(page = 1, perPage = 10, order = 'desc', orderBy = 'date', querySearch = '', filterDate = null, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            querySearch;
            let params = {
                page: page,
                per_page: perPage,
                order: order, // 'asc'
                orderby: orderBy,
                // search: querySearch
                start_date: startDate,
                end_date: endDate,
            };
            if (querySearch) {
                params = { ...params, ...{ search: querySearch } };
            }
            //if (filterDate) {
            // console.log(filterDate)

            // 	console.log(this.moment(filterDate).startOf('month').format('Y-MM-DDTHH:mm:ss'))
            // 	console.log(this.moment(filterDate).endOf('month').format('Y-MM-DDTHH:mm:ss'))
            // params = {...params, ...{after: '2021-12-31T23:00:00'}}
            //params = {...params, ...{start_date: this.moment(filterDate).startOf('month').format('Y-MM-DDTHH:mm:ss')}}
            // 	// params = {...params, ...{before: '2022-01-31T22:59:59'}}
            //params = {...params, ...{end_date: this.moment(filterDate).endOf('month').format('Y-MM-DDTHH:mm:ss')}}
            // 	// 2019-10-03T13:00:00
            //}

            try {
                return await this.axios.get(this.site.url + `/wp-json/tribe/events/v1/events`, {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                    signal: this.controller.signal,
                    params: params,
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getEvent(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.site.url + `/wp-json/tribe/events/v1/events/${id}?_embed=1&context=edit`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async saveEvent(event, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            return await this.axios.post(this.site.url + `/wp-json/tribe/events/v1/events`, event, {
                headers: this.headers,
            });
        },
        async updateEvent(event, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.site.url + `/wp-json/tribe/events/v1/events/${event.id}`, event, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async deleteEvent(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.site.url + `/wp-json/tribe/events/v1/events/${id}`, {
                headers: this.headers,
            });
        },

        async getEventCategories(slug = null, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                per_page: 100,
                hide_empty: 0,
            };
            if (slug) {
                params = { ...params, ...{ slug: slug } };
            }
            return await this.axios.get(this.site.url + `/wp-json/tribe/events/v1/categories`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                params: params,
            });
        },
        /*=====  End of EVENTS  ======*/
        /*===================================
		=            DEPARTMENTS            =
		===================================*/
        async getDepartments(category = null, querySearch = "", page = 1, perPage = 100, order = "asc", orderBy = "title", namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            querySearch;
            let params = {
                page: page,
                per_page: perPage,
                order: order, // 'asc'
                orderby: orderBy,
                search: querySearch,
                status: "any",
            };

            if (category) {
                params = { ...params, ...{ departments_categories: category } };
            }

            try {
                return await this.axios.get(this.site.url + `/wp-json/wp/v2/departments/`, {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                    signal: this.controller.signal,
                    params: params,
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getDepartmentBySlug(slug, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                slug: slug,
                status: "any",
            };
            return await this.axios.get(this.site.url + `/wp-json/wp/v2/departments/?_embed=1&context=edit`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                params: params,
            });
        },
        async saveDepartment(department, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.site.url + `/wp-json/wp/v2/departments/?tw_dashboard_departments=1`, department, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async updateDepartment(department, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.site.url + `/wp-json/wp/v2/departments/${department.id}/?tw_dashboard_departments=1`, department, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async getDepartmentsCategories(namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                per_page: 100,
                hide_empty: 0,
            };
            return await this.axios.get(this.site.url + `/wp-json/wp/v2/departments_categories`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                params: params,
            });
        },
        /*=====  End of DEPARTMENTS  ======*/
        /*===================================
		=            SUBSCRIBERS            =
		===================================*/
        async getSubscribers(page = 1, perPage = 10, order = "desc", orderBy = "date", querySearch = "", namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }

            let params = {
                _embed: 1,
                page: page,
                per_page: perPage,
                order: order,
                orderby: orderBy,
                search: querySearch,
                status: "any",
            };

            try {
                return await this.axios.get(this.site.url + `/wp-json/wp/v2/twd_subscribe?_embed=1&context=edit`, {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                    signal: this.controller.signal,
                    params: params,
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getSubscriber(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                hide_empty: 0,
            };
            return await this.axios.get(this.site.url + `/wp-json/wp/v2/twd_subscribe/${id}/?_embed=1&context=edit`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                params: params,
            });
        },
        async saveSubscriber(subscriber, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.site.url + `/wp-json/wp/v2/twd_subscribe`, subscriber, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async updateSubscriber(subscriber, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.post(this.site.url + `/wp-json/wp/v2/twd_subscribe/${subscriber.id}`, subscriber, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async deleteSubscriber(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.site.url + `/wp-json/wp/v2/twd_subscribe/${id}`, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
            });
        },
        async downloadSubscribersList(namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.site.url + `/wp-json/wp/v2/twd_subscribe/download`, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                responseType: "blob",
                headers: this.headers,
            });
        },
        async getAllowedTerms(namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.site.url + `/wp-json/wp/v2/twd_subscribe/allowed_terms`, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
            });
        },
        /*=====  End of SUBSCRIBERS  ======*/
        async sendTicket(subject, message, namespace) {
            this.prepareForFetch(namespace);
            let user = this.getCurrentUser();
            if (!user) {
                return;
            }
            return await this.axios.post(
                this.site.url + "/wp-json" + this.namespace + "/support-ticket",
                { subject: subject, message: message },
                {
                    headers: this.headers,
                }
            );
        },
        async getStats(from, to, query, namespace) {
            this.prepareForFetch(namespace);
            return await this.axios.get(this.site.url + "/wp-json" + this.namespace + "/site-stats", {
                headers: this.headers,
                params: { from: from, to: to, query: query },
            });
        },
        async uploadFile(file, image = false, namespace) {
            var formData = new FormData();
            image;
            formData.append("file", file);
            this.prepareForFetch(namespace);
            return await this.axios.post(this.site.url + "/wp-json/wp/v2/media", formData, {
                headers: { ...this.headers, ...{ "Content-Type": "multipart/form-data" } },
            });
        },
        async getMediaLibrary(page = 1, perPage = 10, order = "desc", orderBy = "date", querySearch = "", onlyImages = false, fileType = false, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                page: page,
                per_page: perPage,
                order: order, // 'asc'
                orderby: orderBy,
                search: querySearch,
            };
            if (onlyImages && !fileType) {
                params = { ...params, ...{ media_type: "image" } };
            }
            if (fileType) {
                params = { ...params, ...{ media_type: fileType } };
            }
            try {
                return await this.axios.get(this.site.url + `/wp-json/wp/v2/media`, {
                    //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                    headers: this.headers,
                    signal: this.controller.signal,
                    params: params,
                });
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error.message);
                }
            }
        },
        async getMedia(id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.get(this.site.url + `/wp-json/wp/v2/media/${id}`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
            });
        },
        async deleteMedia($id, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            return await this.axios.delete(this.site.url + `/wp-json/wp/v2/media/${$id}`, {
                // http://localhost/tw/milan/wp-json/wp/v2/posts/8517?context=delete
                headers: this.headers,
                params: { force: true },
            });
        },
        async getCategories(slug = null, allowed_terms = null, namespace) {
            this.prepareForFetch(namespace);
            if (!this.site || !this.site.url) {
                return;
            }
            let params = {
                per_page: 100,
                hide_empty: 0,
            };
            if (slug) {
                params = { ...params, ...{ slug: slug } };
            }
            if (Array.isArray(allowed_terms) || allowed_terms === "") {
                if (!allowed_terms.length) {
                    allowed_terms = [""];
                }
                params = { ...params, ...{ include: allowed_terms } };
            }
            return await this.axios.get(this.site.url + `/wp-json/wp/v2/categories`, {
                //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                headers: this.headers,
                params: params,
            });
        },
        checkMixinVersion() {
            console.log("wordpress");
        },
    },
    mixins: [userMixin, siteMixin],
};
